// TempData
import TempDataNavBar from '../TempData_NavBar';
import TempDataMegaMenu from '../TempData_MegaMenu';
import TempDataFooter from '../TempData_footer';
import * as ColorGuide from '../../../cssGuides/colorGuide';

// Page Data
const Data = {
  pageName: 'CookieInfo Test Page',
  pageTemplate: 'Template3',
  pageData: {
    nav: TempDataNavBar,
    megaMenu: TempDataMegaMenu,
    footer: TempDataFooter,
    level_1_Id: 'Page_1',
    pageTitle: 'HTML render test page',
    pageDescription: 'Tester component - HTML render',
    bannerImage: null, // accepts null
    bannerContent: '<h1>Banner text accepts HTML</h1>', // accepts null
    bottomContent: null,
    desktopTools: {}, // accepts null
  },
  pageContent: [
    {
      componentName: 'BlockArea',
      componentData: {
        backgroundColor: ColorGuide.lightGray,
      },
      componentChildren: [
        {
          componentName: 'HtmlRender',
          componentData: {
            html: '<h2 class="text-center">Cookie Information</h2>',
          },
        },
        {
          componentName: 'CookieInformation',
          componentData: {},
        },
      ],
    },
  ],
};

export default Data;
